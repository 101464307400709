import React from "react";
import { MainNavLink, MainNavLinkType } from "./MainNavLink";
import {
  HomeIcon,
  DocumentTextIcon,
  BuildingOffice2Icon,
  BuildingOfficeIcon
} from "@heroicons/react/24/outline";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";

const NAV_LINKS: MainNavLinkType[] = [
  {
    to: "/",
    label: "ダッシュボード",
    icon: <HomeIcon className="h-6 w-6 mr-2" />,
  },
  {
    to: "/funds",
    label: "ファンド",
    icon: <BuildingOffice2Icon className="h-6 w-6 mr-2" />,
    child: [
      {
        to: "/fund",
        label: "ファンド一覧",
      },
      {
        to: "/operator",
        label: "運営者",
      },
      {
        to: "/promotion",
        label: "キャンペーン",
      },
      {
        to: "/documents",
        label: "交付書類",
        child: [
          {
            to: '/bases',
            label: "Base"
          },
        ]
      },
    ],
  },
  {
    to: "/posts",
    label: "記事",
    icon: <DocumentTextIcon className="h-6 w-6 mr-2" />,
    child: [
      {
        to: "/blog",
        label: "ブログ",
      },
      {
        to: "/news",
        label: "お知らせ",
      },
    ],
  },
  {
    to: "/companies",
    label: "会社",
    icon: <BuildingOfficeIcon className="h-6 w-6 mr-2" />,
    child: [
      {
        to: "/user",
        label: "アカウント一覧",
      },
    ],
  },
];

export const Sidebar: React.FC = () => {
  return (
    <div
      id="sidebar"
      className="bg-white px-4 w-64 h-full max-h-full h-screen"
    >
      <div className="w-full">
        <div className="mb-10 text-right border-b">
          <Link to="/">
            <img className="h-16" src={logo} alt="logo" />
          </Link>
          <span className={"text-xs text-secondary-main"}>管理画面</span>
        </div>
        <nav>
          <ul className="flex flex-col gap-2">
            {NAV_LINKS.map((value, index) => (
              <MainNavLink key={index} {...value} />
            ))}
          </ul>
        </nav>
        </div>
    </div>
  );
};
