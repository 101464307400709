import React from "react";

interface ModalLayoutType {
  children: React.ReactNode;
}
export const ModalActionLayout: React.FC<ModalLayoutType> = ({ children }) => (
  <div className="w-full sm:w-2/3 bg-white rounded relative flex flex-col justify-between p-2 overflow-scroll">
    {children}
  </div>
);
