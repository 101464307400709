import { UserContextType } from "../../../types/companyUser";
import axios from "../axios";

class AuthService {
  login(email: string, password: string) {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return axios
      .post<UserContextType>(
        "/api/v1/admin/company/login/access-token/",
        formData,
        { withCredentials: true }
      )
      .then<UserContextType>((res) => res.data)
      .catch((err) => {
        throw err.request.response;
      });
  }
  refresh_token() {
    return axios
      .get<UserContextType>("/api/v1/admin/company/login/refresh-token/", {
        withCredentials: true,
      })
      .then<UserContextType>((res) => res.data)
      .catch((err) => {
        throw err.request.response;
      });
  }

  logout() {
    return axios
      .delete<"success">("/api/v1/admin/company/login/refresh-token/", {
        withCredentials: true,
      })
      .then<"success">(() => "success")
      .catch((err) => {
        throw err.request.response;
      });
  }

  async verifyGmo(code: string, state: string) {
    await axios.post(
      "/api/v1/admin/secure-id/gmo/set-token",
      { code, state },
{ withCredentials: true }
      )
  }
}

export default AuthService;
