import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Sidebar } from "../components/ui/sidebar/Sidebar";

const skipSidebar = [
  /^\/funds\/fund\/create\/?$/,
  /^\/funds\/fund\/\d+\/?$/,
  /^\/posts\/blog\/create\/?$/,
  /^\/posts\/blog\/\d+\/?$/,
  /^\/posts\/news\/create\/?$/,
  /^\/posts\/news\/\d+\/?$/,
  /^\/funds\/promotion\/create\/?$/,
  /^\/funds\/promotion\/\d+\/?$/,
];

export default function Root(): React.ReactElement {
  const location = useLocation();

  return (
    <div className="flex relative h-full justify-between gap-4 w-full mx-auto bg-gray-50">
      {skipSidebar.findIndex((reg) => reg.test(location.pathname)) !== -1 ? (
        <div className="w-full bg-white h-full relative overflow-scroll bg-accent-main">
          <Outlet />
        </div>
      ) : (
        <>
          <Sidebar />
          <div id="details" className="flex-1">
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
}
