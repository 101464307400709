import React from "react";
import { Link } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/20/solid";

export const AddLink: React.FC<NavLinkType> = ({ to, label, state }) => {
  return (
    <Link
      className="px-2 flex py-1 bg-accent-main text-white rounded "
      to={to}
      state={state}
    >
      <span className="flex w-full justify-center">
        <PlusIcon className="h-6 w-6" />
        {label}
      </span>
    </Link>
  );
};
