import React, { forwardRef, useRef, useEffect, useMemo } from "react";

import {
  TableToggleHideAllColumnProps,
  ColumnInstance,
  useTable,
  Column,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
  IdType,
  SortingRule,
} from "react-table";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ArrowDownTrayIcon,
  ChevronUpIcon,
  ChevronUpDownIcon,
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import { numberWithCommas } from "../../utils/numbers";

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: TableToggleHideAllColumnProps, ref: any) => {
    const defaultRef = useRef<HTMLInputElement | null>(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate || false;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <Menu.Item disabled>
        <label role="menuitem" tabIndex={-1}>
          <input type="checkbox" ref={ref} {...rest} />
          <span> すべて表示</span>
        </label>
      </Menu.Item>
    );
  }
);

type ReactTableProps<D extends TableData> = {
  columns: Column<D>[];
  data: D[];
  hiddenColumns?: Array<IdType<D>>;
  initialPageSize?: number;
  initialSort?: SortingRule<D>[];
  pageSizeOptions?: Array<number>;
  dataCount?: number;
  totalPage?: number;
  handleCSV?: (column: ColumnInstance<D>[]) => void;
  rowClick?: (data: D) => void;
  callback?: (page: number, limit: number, order_by: SortingRule<D>[]) => void;
  loading?: boolean;
};

export function ReactTable<D extends TableData>({
  columns,
  data,
  hiddenColumns = [],
  initialPageSize = 100,
  initialSort = [],
  pageSizeOptions = [50, 100, 1000, 2000, 5000, 10000],
  dataCount = data ? data.length : 0,
  totalPage = undefined,
  rowClick = undefined,
  handleCSV = undefined,
  callback = undefined,
  loading,
}: ReactTableProps<D>): React.ReactElement {
  const memorizedColumn = useMemo(() => columns, [columns]);
  const memorizedData = useMemo(() => data, [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, columnResizing },
  } = useTable<D>(
    {
      columns: memorizedColumn,
      data: memorizedData,
      initialState: {
        hiddenColumns: hiddenColumns,
        pageSize: initialPageSize,
        sortBy: initialSort,
      },
      manualSortBy: true,
      manualPagination: true,
      pageCount: totalPage,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (callback) {
      callback(pageIndex, pageSize, sortBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageIndex, sortBy]);

  return (
    <div className="relative w-full">
      <div>
        <div className="toolbar">
          <div className="inline-flex w-full gap-x-2">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  列一覧
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-0 z-10 w-36 flex flex-col origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-2">
                  <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
                  {/* Loop through columns data to create checkbox */}
                  {allColumns.map((column: any) => (
                    <Menu.Item key={column.id} disabled>
                      <label className="inline-flex w-full gap-x-1.5 text-left">
                        <input
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                        />
                        {column.Header}
                      </label>
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
            {handleCSV && (
              <button
                className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                type="button"
                onClick={() => {
                  handleCSV(allColumns);
                }}
              >
                エクスポート
                <ArrowDownTrayIcon
                  className="-mr-1 h-5 w-5 text-yellow-600"
                  aria-hidden="true"
                />
              </button>
            )}
          </div>
        </div>
        <div {...getTableProps()} className="border my-2">
          <div>
            {headerGroups?.map((headerGroup, i) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                key={i}
                className="border-b items-center bg-gray-100 font-bold"
              >
                {headerGroup.headers.map((header) => (
                  <div
                    {...header.getHeaderProps(header.getSortByToggleProps())}
                    className={`inline-flex truncate items-center relative p-2 ${
                      columnResizing.startX ? "pointer-events-none" : ""
                    } ${header?.type === "number" ? "justify-end" : ""}`}
                    key={header.id}
                  >
                    {header.render("Header")}
                    <span>
                      {header.isSorted ? (
                        header.isSortedDesc ? (
                          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" />
                        ) : (
                          <ChevronUpIcon className="-mr-1 h-5 w-5 text-gray-400" />
                        )
                      ) : (
                        <ChevronUpDownIcon className="-mr-1 h-5 w-5 text-gray-400" />
                      )}
                    </span>
                    {/* Use column.getResizerProps to hook up the events correctly */}
                    <div
                      {...header.getResizerProps()}
                      className={`inline-block bg-gray-400 w-[2px] h-full absolute right-0 top-0 translate-x-2/4 z-10 touch-none select-none`}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <div
                  {...row.getRowProps()}
                  onClick={() => {
                    rowClick && rowClick(row.original);
                  }}
                  className="items-start border-b"
                >
                  {row.cells.map((cell) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={`p-1 truncate ${
                          cell.column.type === "number" ? "text-right" : ""
                        }`}
                      >
                        {cell.column.type === "number"
                          ? typeof cell.value === "number" ||
                            typeof cell.value === "string"
                            ? numberWithCommas(cell.value)
                            : cell.render("Cell")
                          : cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div className="truncate p-2">
              {loading ? (
                // Use our custom loading state to show a loading indicator
                <div>Loading...</div>
              ) : (
                <div>
                  {dataCount}件中{pageSize * pageIndex + 1}～
                  {pageSize * pageIndex + (page ? page.length : 0)}件表示
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="inline-flex divide-x-2 gap-x-4 items-center w-full">
        <div className="inline-flex gap-2">
          <button
            onClick={() => {
              gotoPage(0);
            }}
            disabled={!canPreviousPage}
          >
            <ChevronDoubleLeftIcon className="-mr-1 h-5 w-5 text-black" />
          </button>
          <button onClick={previousPage} disabled={!canPreviousPage}>
            <ChevronLeftIcon className="-mr-1 h-5 w-5 text-black" />
          </button>
          <button onClick={nextPage} disabled={!canNextPage}>
            <ChevronRightIcon className="-mr-1 h-5 w-5 text-black" />
          </button>
          <button
            onClick={() => {
              gotoPage(pageCount - 1);
            }}
            disabled={!canNextPage}
          >
            <ChevronDoubleRightIcon className="-mr-1 h-5 w-5 text-black" />
          </button>
        </div>
        <div className="text-sm pl-4">
          <strong>
            {pageCount}ページ中の{pageIndex + 1}ページ
          </strong>
        </div>
        <div className="pl-4">
          <input
            type="number"
            className="w-10 border"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />{" "}
          ページへ
        </div>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {pageSizeOptions.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}件数
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
