import React from "react";
import { TopBar } from "./TopBar";
import {Link} from "react-router-dom";
import {PlusIcon} from "@heroicons/react/20/solid";

type MainLayoutType = {
  title?: string;
  children: React.ReactNode;
  to?:string;
  label?:string;
  icon?:string;
};

export const MainLayout: React.FC<MainLayoutType> = ({
                                                       title,
                                                       to='',
                                                       label,
                                                       icon,
                                                       children }) => {
  return (
    <div className="flex flex-col gap-2 relative h-full">
      <TopBar />
      <div className="bg-white h-full rounded p-4 overflow-scroll scrollbar-hide">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold">{title}</h1>
          {label && <Link className="px-2 flex py-1 bg-accent-main text-white rounded" to={to}>{icon}{label}</Link>}
        </div>


        <main className="my-4  relative flex flex-col ">
          {children}
        </main>
      </div>
    </div>
  );
};
