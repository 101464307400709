export const numberWithCommas = (x: string | number | undefined | null) => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
};

export const convertToNumber = (x: string) => {
  const value = +x.replace(/\D/g, "");
  if (isNaN(value)) {
    return null;
  }
  return value;
};
