import React from "react";
import { ButtonType } from "./types";

export const CancelButton: React.FC<ButtonType> = ({ onClick, value }) => (
  <button
    type="button"
    onClick={onClick}
    className="flex-1 lep-1 text-gray-700 rounded cursor-pointer border border-gray-400 hover:border-gray-500 hover:bg-gray-200"
  >
    {value ? value : "戻る"}
  </button>
);
