import { useContext } from "react";
import AuthService from "../infrastructure/api/company/auth";
import { AuthContext } from "../contexts/auth";

export const useAuth = () => {
  const [user, setUser, authenticated, setAuthenticated] =
    useContext(AuthContext);
  const authService = new AuthService();

  const login = (email: string, password: string) => {
    return authService
      .login(email, password)
      .then((data) => {
        setUser(data);
        setAuthenticated(true);
      })
      .catch((err) => {
        throw JSON.parse(err);
      });
  };

  const logout = () => {
    return authService
      .logout()
      .then(() => {
        setAuthenticated(false);
        setUser(null);
      })
      .catch((err) => {
        throw JSON.parse(err);
      });
  };

  const refreshToken = () => {
    return authService
      .refresh_token()
      .then((data) => {
        setUser(data);
        return data;
      })
      .catch((err) => {
        throw JSON.parse(err);
      });
  };

  return {
    user,
    authenticated,
    login,
    logout,
    refreshToken,
  };
};
