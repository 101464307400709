import React, { useEffect } from "react";
import { BellIcon } from "@heroicons/react/24/outline";
import profileImage from "../../assets/images/profile.png";
import { useAuth } from "../../hooks/useAuth";
import { Popover } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

export const TopBar: React.FC = () => {
  const { user, logout, authenticated } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout().catch((err) => {
      alert(JSON.parse(err).detail);
    });
  };

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  }, [authenticated, navigate]);
  return (
    <div className="rounded bg-white p-2 flex justify-end gap-2 items-center">
      <BellIcon className="h-6 w-6 text-gray-500" />
      <div className="whitespace-pre">{user?.full_name}</div>
      <Popover className="relative">
        <Popover.Button as={React.Fragment}>
          <img
            className="w-10 h-10 p-1 rounded-full ring-2 ring-yellow-400"
            src={user?.thumbnail || profileImage}
            alt="Rounded avatar"
          />
        </Popover.Button>
        <Popover.Panel className="absolute w-max right-0 bg-white border rounded mt-1">
          <ul>
            <li className="hover:bg-amber-200/10 p-2">
              <button onClick={handleLogout}>ログアウト</button>
            </li>
          </ul>
        </Popover.Panel>
      </Popover>
    </div>
  );
};
