import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

export interface MainNavLinkType extends NavLinkType {
  child?: NavLinkType[];
}

const ChildNavLink: React.FC<NavLinkType> = ({ to, label, child, icon }) => {
  return (
    <>
      { child
        ?
        <MainNavLink to={to} label={label} child={child} icon={icon}/>
        :
        <li className="flex border-l border-[#E8E8E8] items-center before:content-['-'] before:text-[#E8E8E8]">
          <NavLink
            className={({ isActive }) => {
              return isActive ? "active-nav-link" : "nav-link";
            }}
            to={to}
          >
            {label}
          </NavLink>
        </li>
      }
    </>
  );
};

export const MainNavLink: React.FC<MainNavLinkType> = ({
  to,
  label,
  child,
  icon,
}) => {
  const ulRef = useRef<HTMLUListElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen((prevState) => !prevState);
    if (ulRef.current) {
      if (ulRef.current.className === "hidden") {
        ulRef.current.className = "block ml-4 flex flex-col gap-1 my-1";
      } else {
        ulRef.current.className = "hidden";
      }
    }
  };
  return (
    <li className="mx-1 w-full">
      {!child ? (
        <NavLink
          className={({ isActive }) => {
            return isActive ? "active-nav-link" : "nav-link";
          }}
          to={to}
        >
          <span className="flex">
            {icon}
            {label}
          </span>
        </NavLink>
      ) : (
        <>
          <NavLink
            to={to}
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            {({ isActive }) => (
              <button
                className={isActive ? "active-nav-link" : "nav-link"}
                onClick={handleClick}
                type="button"
              >
                <span className="flex">
                  {icon}
                  {label}
                </span>
                {!open ? (
                  <ChevronDownIcon className="h-6 w-6" />
                ) : (
                  <ChevronUpIcon className="h-6 w-6" />
                )}
              </button>
            )}
          </NavLink>
          <ul className="hidden" ref={ulRef}>
            {child.map((value, index) => {
              return (
                <ChildNavLink
                  key={index}
                  to={`${to}${value.to}`}
                  label={value.label}
                  child={value.child}
                  icon={value.icon}
                />
              );
            })}
          </ul>
        </>
      )}
    </li>
  );
};
